import { CxCard, CxTabButton, CxWrap, Line } from '../../../components/Sx';
import FormInputField from '../../../../common/components/formElements/FormInputField';
import FormTextArea from '../../../../common/components/formElements/FormTextArea';

import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import ControlledFormSelectDrop from '../../../../common/components/formElements/controlled/ControlledFormSelectDrop';
import { useEffect, useMemo, useState } from 'react';
import { City, Own, SecondarySpecialty } from '../../../services/DoctorProfile';
import CustomSuccessModal from '../../../../common/components/customSuccessModal/CustomSuccessModal';
import FailNotification from '../../../../common/components/failNotification/FailNotification';

const latRegExp = /^[+-]?(?:([0-8]\d{0,1}(\.\d+){0,})|(90))?$/;
const longRegExp = /^[+-]?(?:([1]{1}[0-7]\d{0,1}(\.\d+){0,})|([0]{0,1}[0-9]\d{0,1}(\.\d+){0,})|(180))?$/;

const formSchema = z.object({
  patientContactPhone1: z.string({ required_error: 'Requerido' }).nonempty({ message: 'Requerido' }),
  patientContactPhone2: z.string(),
  consultationCost: z.string({ required_error: 'Requerido' }).nonempty({ message: 'Requerido' }),
  examCost: z.string(),
  cash: z.boolean(),
  card: z.boolean(),
  transfers: z.boolean(),
  remesa: z.boolean(),
  bitcoin: z.boolean(),
  governmentPermitInfo: z.string(),
  generalInfo: z.string(),
  insurance: z.string(),
  address: z.string(),
  // lat_long: z.string(),
  idCity: z.string({ required_error: 'Requerido' }).refine((value) => {
    return value.trim().length > 0 ;
  }, 'Requerido'),
  latitude:z.string().regex(latRegExp, { message: 'Número no válido, la latitud debe ser un número entre -90 y 90' }),
  longitude:z.string().regex(longRegExp, { message: 'Número no válido, la longitud debe ser un número entre -180 y 180' }),
})

const OwnProfile = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [successBody, setSuccessBody] = useState('');
  const [ errorMsg, setErrorBody] = useState('');
  const [allCities, setAllCities] = useState([]);


  const { register, handleSubmit, formState, getValues, reset, watch } = useForm({ resolver: zodResolver(formSchema) });
  const { errors } = formState;

  const watchedCity = watch('idCity');

  const closeError = () => {
    setShowErrorModal(false)
  };
  const saveForm = (values) => {
    const { latitude, longitude, idCity, card, cash, bitcoin, remesa, transfers } = values;
    const paymentMethodsArray = [];
    if (remesa) paymentMethodsArray.push('Remesa');
    if (transfers) paymentMethodsArray.push('Transferencias');
    if (bitcoin) paymentMethodsArray.push('Bitcoin');
    if (card) paymentMethodsArray.push('Tarjeta de Crédito');
    if (cash) paymentMethodsArray.push('Efectivo');

    const paymentMethods = paymentMethodsArray.join(', ') || false;
    Own.updateProfile({ ...values, latitude, longitude, idCity: Number(idCity), paymentMethods })
      .then(() => _createSuccessMessage('Success'))
      .catch((err) => {
        setShowErrorModal(true)
        setErrorBody(err.response.data.message)
      });
  };

  const _createSuccessMessage = (message) => {
    setShowSuccessModal(true);
    setSuccessBody(message);
  };

  const onCloseSuccessMessage = () => {
    setShowSuccessModal(false);
    setSuccessBody('');
  };

  // const allSecSpecialties = async () => {
  //   const getAllSpecialty = await SecondarySpecialty.getAllSecondarySpecialty();
  //   if (getAllSpecialty) {
  //     const allFormattedsecSpecialties = getAllSpecialty.data.map((specialty) => ({
  //       id: specialty.id,
  //       label: specialty.name,
  //       key: specialty.idLanguageByDoctor
  //     }));
  //     // console.log({ allFormattedsecSpecialties });
  //     setAllSecSpecialties(allFormattedsecSpecialties);
  //   }
  // };

  const queryAllCities = async () => {
    const getAllLanguages = await City.getCityList();
    if (getAllLanguages) {
      const formattedAllLanguages = getAllLanguages.data.map((city) => ({ id: city.id, label: city.name }));
      // console.log(formattedAllLanguages);
      setAllCities(formattedAllLanguages);
    }
  };

  useEffect(() => {
    // allSecSpecialties();
    Own.getProfile()
      .then((res) => {
        reset({
          patientContactPhone1: res.data.patientContactPhone1 ?? '',
          patientContactPhone2: res.data.patientContactPhone2 ?? '',
          consultationCost: res.data.consultationCost ?? '',
          examCost: res.data.examCost ?? '',
          governmentPermitInfo: res.data.governmentPermitInfo ?? '',
          generalInfo: res.data.generalInfo ?? '',
          insurance: res.data.insurance ?? '',
          address: res.data.address ?? '',
          idCity: res.data.idCity.toString() ?? '',
          cash: res.data.paymentMethods?.includes('Efectivo') ?? false,
          bitcoin: res.data.paymentMethods?.includes('Bitcoin') ?? false,
          remesa: res.data.paymentMethods?.includes('Remesa') ?? false,
          transfers: res.data.paymentMethods?.includes('Transferencias') ?? false,
          card: res.data.paymentMethods?.includes('Tarjeta de Crédito') ?? false,
          latitude : res.data.latitude ?? '',
          longitude:res.data.longitude ?? '',
          // lat_long: res.data.latitude ? res.data.latitude + res.data.longitude : ''
        });
      })
      .catch((err) => console.log(err));
    queryAllCities();
    console.clear();
  }, []);

  // console.log(
  //   'game',
  //   allSecSpecialty.find((item) => item.id === getValues().idSpecialty)
  // );

  return (
    <form onSubmit={handleSubmit(saveForm)}>
      <CxWrap>
        <CxCard>Contacto</CxCard>
        <Line />
        <CxCard>
         
          <div className="grid grid-cols-2  gap-x-32 gap-y-4 pb-8 px-4">
            <div className="form-label-box">
              <FormInputField identifier="patientContactPhone1" label="Teléfono Principal*" type="string" helperText={'Requerido'} registration={register('patientContactPhone1')} errorMessage={errors.patientContactPhone1?.message} />
             </div>
            <div>
              <FormInputField identifier="patientContactPhone2" label="Teléfono Secundario" type="string" registration={register('patientContactPhone2')} errorMessage={errors.patientContactPhone2?.message} />
            </div>
          </div>
          
        </CxCard>
      </CxWrap>

      <CxWrap>
        <CxCard>Costos</CxCard>
        <Line />
        <CxCard>
          <div className="grid grid-cols-2 gap-4 gap-x-32 gap-y-4 pb-8 px-4">
            <div className="form-label-box">
              <FormInputField identifier="consultationCost" label="*Costo de consulta" helperText={'Requerido'} type="string" registration={register('consultationCost')} errorMessage={errors.consultationCost?.message} />
            </div>
            <div>
              <FormInputField identifier="examCost" label="Costo de examen" type="string" registration={register('examCost')} errorMessage={errors.examCost?.message} />
            </div>
          </div>
          <div className='px-4'>
            <div className="mb-4">Métodos de pago </div>
            <div className="flex items-center mb-4">
              <input id="cash" type="checkbox" name="cash" className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 rounded-full" {...register('cash')} />
              <label htmlFor="cash" className="text-sm font-medium text-gray-900 ml-2 block">
                Efectivo
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input id="card" type="checkbox" name="card" className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 rounded-full" {...register('card')} />
              <label htmlFor="card" className="text-sm font-medium text-gray-900 ml-2 block">
                Tarjeta de Crédito
              </label>
              {/* </label> 
              <div class="custom-checkk-box">
                <input type="checkbox" checked="checked"></input>
                <span class="checkmark"></span>
                <label>Efectivo</label>
              </div> */}

            </div>
            <div className="flex items-center mb-4">
              <input id="transfers" type="checkbox" name="transfers" className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 rounded-full" {...register('transfers')} />
              <label htmlFor="transfers" className="text-sm font-medium text-gray-900 ml-2 block">
                Transferencias
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input id="bitcoin" type="checkbox" name="bitcoin" className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 rounded-full" {...register('bitcoin')} />
              <label htmlFor="bitcoin" className="text-sm font-medium text-gray-900 ml-2 block">
                Bitcoin
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input id="remesa" type="checkbox" name="remesa" className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 rounded-full" {...register('remesa')} />
              <label htmlFor="remesa" className="text-sm font-medium text-gray-900 ml-2 block">
                Remesa
              </label>
            </div>
          </div>
        </CxCard>
      </CxWrap>

      <CxWrap>
        <CxCard>Permiso para ejercer</CxCard>
        <Line />
        <CxCard>
          <div className="grid grid-cols-6 gap-4  pb-8 px-4">
            <div className="col-start-1 col-end-7 form-label-box">
              <FormInputField identifier="governmentPermitInfo" label="Permiso para ejercer" type="string" registration={register('governmentPermitInfo')} />
            </div>
          </div>
        </CxCard>
      </CxWrap>
      <CxWrap>
        <CxCard>Sobre el consultorio</CxCard>
        <Line />
        <CxCard>
          <div className="grid grid-cols-6 gap-4  pb-8 px-4">
            <div className="col-start-1 col-end-12 form-label-box">
              <FormTextArea identifier="generalInfo" label="Informacion general de consulta" type="string" registration={register('generalInfo')} errorMessage={errors.generalInfo?.message} />
            </div>
            <div className="col-start-1 col-end-12 form-label-box">
              <FormInputField identifier="insurance" label="Aseguradoras con las que trabaja" type="string" registration={register('insurance')} errorMessage={errors.insurance?.message} />
            </div>
            <div className="col-start-1 col-end-12 form-label-box">
              <FormInputField identifier="address" label="Dirección del consultorio" type="string" registration={register('address')} errorMessage={errors.address?.message} />
            </div>
            {/* <div className="col-start-1 col-end-7 form-label-box">
              <FormInputField identifier="lat_long" label="Latitud y longitud" type="string" registration={register('lat_long')} errorMessage={errors.lat_long?.message} />
              <p className="text-right text-xs mt-2">Si necesita ayuda, lea este artículo sobre como sacar coordenadas de Google Maps.</p>
            </div> */}

            <div className="col-start-1 col-end-12 form-label-box">
              <FormInputField identifier="latitude" label="Latitud" type="string" registration={register('latitude')} errorMessage={errors.latitude?.message} />
            </div>
            <div className="col-start-1 col-end-12 form-label-box">
              <FormInputField identifier="longitude" label="Longitud" type="string" registration={register('longitude')} errorMessage={errors.longitude?.message} />
             <p className="text-right text-xs mt-2 font-medium	"><a  target="_blank" href="https://support.google.com/maps/answer/18539?hl=en&co=GENIE.Platform%3DDesktop#:~:text=Get%20the%20coordinates%20of%20a,decimal%20format%20at%20the%20top.">
               Si necesita ayuda, lea este <span className='text-blue underline	'>artículo sobre como sacar coordenadas de Google Maps.</span></a></p>
            </div>
            <div className="col-start-1 col-end-12 form-label-box">
              <ControlledFormSelectDrop label={'Ciudad'} identifier={'idCity'} options={allCities} value={watchedCity} registration={register('idCity')} helperText={'Requerido'} errorMessage={errors.idCity?.message} />
            </div>
          </div>
        </CxCard>
      </CxWrap>
      <div className=" pt-12 pr-12  text-right ">
        <CxTabButton label="Actualizar" />
      </div>
      {showErrorModal && <FailNotification
                    displayNotification={showErrorModal}
                    setCloseNotification={() => closeError()}
                    title={'Falla!'}
                    bodyText={errorMsg}
                />}
      {showSuccessModal && <CustomSuccessModal title={'Acción ejecutada con éxito!'} bodyText={successBody} buttonText={'Cerrar'} buttonAction={onCloseSuccessMessage} open={showSuccessModal} onClose={onCloseSuccessMessage} />}
    </form>
  );
};

export default OwnProfile;
