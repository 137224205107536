import NavigationElements from "./pageElements/NavigationElements";
import { useEffect, useState } from "react";

export default function BlogPage(props) {

    const {displayNavigation, onUpdateDisplayNavigation} = props;

    const [blogPosts, setBlogPosts] = useState([]);

    useEffect(() => {
        const fetchBlogPosts = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_SERVER}/blogs/published`, {
                method: "GET"
            });
            const data = await response.json();
            console.log(data, 'data');
            setBlogPosts(data);
        }
        fetchBlogPosts();
    }, []);
  
    return <>
        <NavigationElements
            displayNavigation={displayNavigation}
            onUpdateDisplayNavigation={onUpdateDisplayNavigation}
        />
        <div className="blog-page container mx-auto p-4 min-h-[29.8rem]">
            {
                blogPosts.length === 0 ? (
                    <div className="flex flex-col items-center justify-center h-full">
                        <img src="/no-data.png" alt="No Blogs Yet" className="w-1/2 h-[26.8rem] mb-4" />
                    </div>
                ) : (
                    <main className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {blogPosts.map(post => (
                            <article key={post.id} className="mb-6 border p-4 rounded-lg shadow-lg bg-white">
                                <img src={process.env.REACT_APP_MEDIA_SERVER+'/'+post.image} alt={post.title} className="w-full h-40 object-cover mb-4 rounded" />
                                <h2 className="text-2xl font-semibold text-[#005cb9]">{post.title}</h2>
                                <p className="text-sm text-[#00b6fc]">{post.author} | {post.date}</p>
                                <p className="text-base mb-4 text-gray-800"></p>
                                <a href={`/blog/${post.id}`} className="text-[#005cb9] hover:text-[#004a94] inline-flex items-center">
                                    Learn More <span className="ml-2">&rarr;</span>
                                </a>
                            </article>
                        ))}
                    </main>
                )
            }
        </div>
    </>
}
