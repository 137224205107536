import NavigationElements from "./pageElements/NavigationElements";
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from "react";

export default function BlogPost(props) {
    const {displayNavigation, onUpdateDisplayNavigation} = props;
    const { id } = useParams();
    const [blog, setBlog] = useState({});

    useEffect(() => {
        const fetchBlogPost = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_SERVER}/blogs/${id}`, {
                method: "GET"
            });
            const data = await response.json();
            setBlog(data[0]);
        }
        fetchBlogPost();
    }, []);

    return (
        <>
            <NavigationElements
                displayNavigation={displayNavigation}
                onUpdateDisplayNavigation={onUpdateDisplayNavigation}
            />
            <div className="blog-post-page bg-gray-100 min-h-screen p-4 min-h-[29.8rem]">
                {
                    !blog?.id ? (
                        <div className="flex flex-col items-center justify-center h-full">
                            <img src="/no-data.png" alt="No Blogs Yet" className="w-1/2 h-[26.8rem] mb-4" />
                        </div>
                    ) : <div className="container mx-auto p-4 bg-white rounded-lg shadow-lg">
                    <Link to="/blog" className="text-[#005cb9] hover:text-[#004a94] mb-4 inline-block">
                        &larr; Back to Blogs
                    </Link>
                    <article className="p-4">
                        <div className="flex flex-col md:flex-row mb-4 bg-[#f0f0f0] p-4 rounded-lg">
                            <div className="md:w-1/2 mb-4 md:mb-0 md:pr-4">
                                <h2 className="text-4xl font-semibold text-[#005cb9] mb-2">{blog.title}</h2>
                                <p className="text-lg text-[#004a94] mb-4">{blog.author} | {blog.date}</p>
                            </div>
                            <div className="md:w-1/2">
                                <img src={process.env.REACT_APP_MEDIA_SERVER+'/'+blog.image} alt={blog.title} className="w-full h-64 object-cover rounded-lg" />
                            </div>
                        </div>
                        <p className="text-base mb-4 text-gray-800 leading-relaxed" dangerouslySetInnerHTML={{ __html: blog.content }}></p>
                    </article>
                </div>
                }
                
            </div>
        </>
    );
}