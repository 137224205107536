import NavigationElements from "./pageElements/NavigationElements";
import useAuthentication from "../api/hooks/useAuthentication";

export default function PlansPage(props) {

    const {displayNavigation, onUpdateDisplayNavigation} = props;
    const { submitContactUs } = useAuthentication();
  
    return <>
        <NavigationElements
            displayNavigation={displayNavigation}
            onUpdateDisplayNavigation={onUpdateDisplayNavigation}
        />
    </>
}
