import whatsappimg from './../../images/whatsapp-icon.png';
import { useState } from 'react';
import SuccessConfirmation from '../../common/components/successConfirmation/SuccessConfirmation';
import FailConfirmation from '../../common/components/failConfirmation/FailConfirmation';

import { z } from 'zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
const formSchema = z.object({
  name: z.string().min(1, 'Se requiere el nombre').max(100),
  phone: z.string().min(1, 'El teléfono es requerido').regex(phoneRegExp, { message: 'Numero de teléfono inválido' }).max(12, { message: 'El teléfono debe contener como máximo 12 números' }),
  countryCode: z.string().min(1, 'Se requiere código de país'),
  message: z.string().min(1, 'El mensaje es obligatorio').max(100),
  email: z.string().min(1, 'Correo electrónico es requerido').email('Correo electrónico inválido')
})
export default function ContactUsFormElement(props) {
  const { onContactUsSubmit } = props;
  const [successMessage, setSuccessMessage] = useState(null);
  const [failMessage, setFailMessage] = useState(null);
  const {
    register,
    handleSubmit, reset,
    formState: { errors, isSubmitting }
  } = useForm({ resolver: zodResolver(formSchema) });

  const resetState = () => {
    reset({
      name: '',
      email: '',
      countryCode: 'SV',
      phone: '',
      message: ''
    })
  };
  const onSubmit = async (data) => {
    try {
      const checkResult = await onContactUsSubmit(data);
      if (checkResult) {
        setSuccessMessage('Contáctenos enviado con éxito');
        resetState();
        setTimeout(() => {
          setSuccessMessage(null);
        }, 2000);
      } else {
        setFailMessage('Ocurrió un error. Inténtelo de nuevo.');
        setTimeout(() => setFailMessage(null), 5000);
      }
    } catch (e) {
      setFailMessage('Ocurrió un error. Inténtelo de nuevo.');
      setTimeout(() => setFailMessage(null), 5000);
    }
  };
  // const onSubmit = (data) => {
  // };
  return (
    <div className=" mt-10 mb-20">
      <div className="relative max-w-3xl mx-auto ">
        <div className="bg-white rounded-lg px-20 pt-12 pb-10">
          <div className="text-center">
            <figure className="text-center flex justify-center mb-5">
              <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M29.42 58.008C45.4061 58.008 58.3654 45.0487 58.3654 29.0626C58.3654 13.0765 45.4061 0.117203 29.42 0.117203C13.4339 0.117203 0.474609 13.0765 0.474609 29.0626C0.474609 45.0487 13.4339 58.008 29.42 58.008ZM40.216 21.83C40.8236 21.0754 40.7045 19.9711 39.9498 19.3635C39.1952 18.7558 38.0909 18.875 37.4832 19.6296L25.253 34.8188L21.1375 29.7156C20.5293 28.9614 19.4249 28.8431 18.6707 29.4513C17.9166 30.0595 17.7982 31.1639 18.4064 31.9181L23.8885 38.7159C24.2217 39.129 24.724 39.3691 25.2547 39.3689C25.7855 39.3687 26.2876 39.1282 26.6204 38.7148L40.216 21.83Z"
                  fill="#00B3FF"
                />
              </svg>
            </figure>
            <p className="color-custom font-semibold text-xl">
              ¿Tiene alguna pregunta sobre nuestros servicios? <br></br>
              No dude en consultarnos y nosotros nos pondremos en contacto con usted.
            </p>
            {/* TODO */}
            <div className="h-5 my-4 contents">
              {Boolean(successMessage) && <SuccessConfirmation message={successMessage} onClose={() => setSuccessMessage(null)} />}
              {Boolean(failMessage) && <FailConfirmation errorMessage={failMessage} onClose={() => setFailMessage(null)} />}
            </div>
          </div>
          <div className="mt-12">
            <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="sm:col-span-2 w-64 m-auto mb-4">
                <div className="mt-1">
                  <input type="text" id="name" className="py-3 px-4  w-full rounded-full bg-sky-50 text-center border-0 placeholder:font-light" placeholder="Nombre" {...register('name')} />
                  {errors.name && <span className="mt-2 text-sm text-red-700">{errors.name?.message}</span>}
                </div>
              </div>

              <div className="sm:col-span-2  w-64 m-auto mb-4">
                <div className="mt-1">
                  <input id="email" name="email" type="text" autoComplete="email" {...register('email')} className="py-3 px-4  w-full rounded-full bg-sky-50 text-center border-0 placeholder:font-light" placeholder="Correo" />
                  {errors.email && <span className="mt-2 text-sm text-red-700">{errors.email?.message}</span>}
                </div>
              </div>
              <div className="sm:col-span-2  w-64 m-auto mb-8">
                <div className="mt-1 relative rounded-full ">
                  <div className="absolute inset-y-0 left-0 flex items-center">
                    <label htmlFor="country" className="sr-only">
                      Country
                    </label>
                    <select id="country" name="country" {...register('countryCode')} className="h-full py-0 pl-4 pr-8  rounded-full color-custom  bg-sky-50 border-0 font-light">
                      <option value="US">SV</option>
                      <option value="US">US</option>
                      <option value="CA">CA</option>
                      <option value="EU">EU</option>
                    </select>
                  </div>
                  <input
                    type="text"
                    name="phone-number"
                    id="phone-number"
                    autoComplete="tel"
                    {...register('phone')}
                    className="py-3 px-4  w-full rounded-full bg-sky-50 text-center border-0 pl-12 placeholder:font-light"
                    placeholder="+503 7657-1234"
                  />
                </div>
                {errors.countryCode && <span className="mt-2 text-sm text-red-700">{errors.countryCode?.message}</span>}
                {errors.phone && <span className="mt-2 text-sm text-red-700">{errors.phone?.message}</span>}

              </div>
              <div className="sm:col-span-2 mb-4">
                <div className="mt-1 text-center">
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    {...register('message')}
                    className="py-3 px-4 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 bg-sky-50 border-0 resize-none h-40 text-center"
                    defaultValue={''}
                    placeholder="Su mensaje:"
                  />
                  {errors.message && <span className="mt-2 text-sm text-red-700">{errors.message?.message}</span>}
                </div>
              </div>
              <div className="sm:col-span-2 flex items-center justify-center">
                <button
                  type="submit"
                  className=" px-20 py-3  rounded-full shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 custome-bg"
                  disabled={isSubmitting}
                >
                  Enviar
                </button>
              </div>
            </form>
            <div className='w-full mt-2 text-center'>
              <a className='text-sm text-[#005cb9] font-semibold hover:underline' href='https://sites.google.com/doctolinkapp.com/doctolinksv/página-principal?fbclid=PAZXh0bgNhZW0CMTEAAaYFCf5MV5OZYCGzoo4BeawHMpFFoZOkz_RXM7P6pFVLle6Leo5DpAVzZV8_aem_Ho5-VTHsFiDq-S80wHn25w'>
                ¿Quieres agendar una Demo?
              </a>
            </div>
          </div>
        </div>


        <div className="whatsapp-number  mt-10 flex items-center justify-center">
          <a href="https://api.whatsapp.com/send?phone=70820900" target='_blank' className='flex items-center'>
            <figure className="z-10	">
              <img className="" src={whatsappimg} alt="" />
            </figure>

            <figcaption className="rounded-full bg-sky-50 color-custom py-4 px-8 text-md">
              <a>+503 7082-0900</a>
            </figcaption>
          </a>
        </div>
      </div>
    </div >
  );
}
